<template>
  <div>
    <standard-page title="Permissions" :definition="$DEFINITIONS.admin.permissions">
      <template v-slot:breadcrumb>
        <span>
          <router-link :to="{ name: 'admin-home'}">Admin</router-link>
        </span>
        <span>Permissions</span>
      </template>

      <template v-slot:buttons>
        <button class="btn btn-orange rounded font-weight-bold" data-target="#addModal" data-toggle="modal" title="Add Rate">
          <i class="fas fa-plus-square mg-r-10" />Add Permission
        </button>
      </template>
      <template v-slot:content>
        Service: <select aria-controls="service" aria-hidden="true" class="custom-form-control" tabindex="-1" v-model="serviceid">
          <option v-for="service in services" :value="service.service">{{service.name}}</option>
        </select>
        <br> <br>
        <data-table :api="api" :columns="columns" :rename="renamePermission" @ready="onReady" list-key="permissions" :key="serviceid">
        </data-table>
      </template>
    </standard-page>

    <delete-modal :service="'accounts'" :dontShowSuccessToastr="true" :context="'Permission'" :table="table" :url="`accounts/permissions/${permissionIdtoDelete}`" v-if="permissionIdtoDelete"></delete-modal>

    <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Permission</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleAdd">
            <div class="modal-body">
              <div class="form-group row">
                <div class="col-sm-7">
                  <input type="text" :class="{'is-invalid': submitted && errors.has('name')}" class="form-control" id="name" name="name" data-vv-as="Name" v-validate="'required'" v-model="permission.name" placeholder="Name">
                  <div class="invalid-feedback" v-if="submitted && errors.has('name')">{{ errors.first('name') }}</div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import EventBus from "@/event-bus";
export default {
  name: "Permissions",
  computed: {
    api() { return `${this.$accounts.defaults.baseURL}/accounts/permissions?service_id=${this.serviceid}`; },
  },
  data() {
    return {
      tax: {},
      submitted: false,
      rate: {},
      columns: [
        { type: 'name' },
        {
          title: 'Action',
          data: null,
          defaultContent: `
            ${this.$StandardButtons.renameButton('Rename Catalog')}
            ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Delete Catalog')}
          `
        }
      ],
      table: null,
      serviceid: 1,
      permission: {
        name: null
      },
      permissionIdtoDelete: null,
      services: null
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      let result = await this.$accounts.get(`accounts/services`);
      this.services = result.data.services;
    },
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#main-table tbody').on('click', '.delete', function () {
          let entity = table.row($(this).parents('tr')).data();
          self.setPermissionIdToDelete(entity.permission);
          table.ajax.reload();
        });

      });

    },

    handleAdd() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addPermission();
        }
      });
    },
    async addPermission() {
      try {
        let permission = {
          service_id: this.serviceid,
          name: this.permission.name,
        };
        await this.$accounts.post(`accounts/permissions`, permission);
        this.table.ajax.reload();
      } catch (e) {
        console.error(e);
        this.$toastr.e('Permission add failed', 'Error');
      } finally {
        $('#addModal').modal('hide');
      }
    },
    async renamePermission(permission, name) {
      if (name.trim().length > 0) {
        try {
          EventBus.$emit('openLoader');
          await this.$accounts.patch(`accounts/permissions/${permission.permission}`, { name });
        } catch (e) {
          console.log(e);
          this.$toastr.e("Permission update failed", 'Error');
        } finally {
          EventBus.$emit('closeLoader');
          this.table.ajax.reload();
        }
      } else {
        this.$toastr.e("Please provide a name first!", 'Failed');
      }
    },
    setPermissionIdToDelete(permissionId) {
      this.permissionIdtoDelete = permissionId;
    },

  }
}
</script>

<style scoped>
</style>